<script>
import {Loading} from 'element-ui';
import accountApi from '../apis/accountApi';
import homeApi from '../apis/homeApi';
import storage from '../storage';

/**
 * Navbar component
 */
export default {
  data() {
    return {
      isCondensed: false,
      alert: {
        content: "",
        presignedUrl: null,
        ifAlert: false,
        type: 2
      },
	   email:""
    };
  },
  props: {
    isWhiteNavbar: {
      type: Boolean,
    },
    navLight: {
      type: Boolean,
    },
    isIcons: {
      type: Boolean,
    },
  },
  mounted() {
    let userId = storage.localGet("userId")
    if (userId) {
        this.login = true
        this.email = userId.email
       }
    window.onscroll = function () {
      onwindowScroll();
    };
    //获取消息10秒一次
    // homeApi.notification(res => {
    //   this.alert = res.data
    // })

    // setInterval (() => {
    //   homeApi.notification(res => {
    //     //相同的内容不在展示
    //     if (this.alert.content == res.data.content) {
    //       res.data.ifAlert = this.alert.ifAlert
    //     }
    //     this.alert = res.data
    //   })
    // }, 10 * 1000)
    function onwindowScroll() {
      if (
          document.body.scrollTop > 50 ||
          document.documentElement.scrollTop > 50
      ) {
        document.getElementById("topnav").classList.add("nav-sticky");
      } else {
        document.getElementById("topnav").classList.remove("nav-sticky");
      }

      if (
          document.body.scrollTop > 100 ||
          document.documentElement.scrollTop > 100
      ) {
        document.getElementById("back-to-top").style.display = "inline";
      } else {
        document.getElementById("back-to-top").style.display = "none";
      }
    }

    var links = document.getElementsByClassName("side-nav-link-ref");

    var matchingMenuItem = null;
    for (let i in links) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("active");
            const parent4 = parent3.parentElement;
            if (parent4) {
              const parent5 = parent4.parentElement;
              parent5.classList.add("active");
            }
          }
        }
      }
    }
  },
  methods: {
    /**
     * Toggle menu
     */
    toggleMenu() {
      this.isCondensed = !this.isCondensed;
      if (this.isCondensed) {
        document.getElementById("navigation").style.display = "block";
      } else document.getElementById("navigation").style.display = "none";
    },

    /**
     * Menu clicked show the submenu
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling.nextSibling;

      if (nextEl && !nextEl.classList.contains("open")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("open");
        }
        nextEl.classList.add("open");
      } else if (nextEl) {
        nextEl.classList.remove("open");
      }
      return false;
    },
    logout() {
      const load = Loading.service({
        lock: true,
        text: 'Sign out...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      accountApi.logout(() => {
        load.close()
        storage.localDel("userId")
        if (location.href != "/") {
          location.href = "/"
        } else {
          location.reload()
        }

      })
    }
  },
};
</script>
<style lang="scss" scoped>


.imageAlert {
  z-index: 99999;
  border-radius: 0;

  img {
    height: 100px;
  }

  @media screen and (max-width: 768px) {
    position: fixed;
    left: 0;
    margin: 2.5%;
    width: 95%;
    border-radius: 5px;
    display: flex;
    align-items: center;
    height: 95%;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
</style>
<template>
  <div>
    <!-- Navbar STart -->
    <header id="topnav" class="defaultscroll sticky"
            :class="{ 'bg-white': isWhiteNavbar === true }">
      <b-alert v-if="alert.type==2" class="mb-0 text-center" style="border-radius:0" @dismissed="alert.ifAlert = false"
               v-model="alert.ifAlert" variant="primary" dark dismissible>{{
          alert.content
        }}
      </b-alert>
      <b-alert v-else class="mb-0 text-center imageAlert" v-model="alert.ifAlert" variant="primary"
               @dismissed="alert.ifAlert = false" dark dismissible>
        <img :src="alert.presignedUrl"/>
      </b-alert>
      <div class="container">
        <!-- Logo container-->
        <div>
          <router-link class="logo" to="/" v-if="navLight !== true">
            <img src="/images/logo-dark.png" height="32" alt=""/>
          </router-link>
          <router-link class="logo" to="/" v-else>
            <img src="/images/logo-dark.png" class="l-dark" height="32" alt=""/>
            <img src="/images/logo-light.png" class="l-light" height="32" alt=""/>
          </router-link>
        </div>
        <div class="buy-button" v-if="isIcons !== true">
             <el-dropdown trigger="click">
              <span class="el-dropdown-link" style="display: flex;align-items: center;">
               <img src="../../public/images/img1.png"
               style="width: 30px;height: 30px;border-radius: 50%;"
               class="img-fluid" alt=""/>
               <span class="window" style="margin-left: 10px;user-select: none;">{{email}}</span>
              </span>
              <el-dropdown-menu slot="dropdown" placement="bottom">
               <router-link to="/profile/setting">
                <el-dropdown-item>
                 Account Settings
                </el-dropdown-item>
               </router-link>
               <a @click="logout">
                <el-dropdown-item>
                 Sign out
                </el-dropdown-item>
               </a>
              </el-dropdown-menu>
             </el-dropdown>
            </div>
        <ul class="buy-button list-inline mb-0" v-if="isIcons === true">
          <li class="list-inline-item mb-0 developer-icon">
            <b-dropdown right variant="link" toggle-class="text-decoration-none p-0 pr-2"
                        menu-class="dd-menu dropdown-menu-right bg-white shadow rounded border-0 mt-3 py-0">
              <template #button-content>
                <i class="mdi mdi-magnify h4 text-muted"></i>
              </template>
              <div style="width: 300px">
                <form>
                  <input type="text" id="text" name="name" class="form-control border bg-white"
                         placeholder="Search..."/>
                </form>
              </div>
            </b-dropdown>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-github mdi-18px icons"></i></a>
          </li>
          <li class="list-inline-item mb-0 pr-2">
            <a href="#" class="btn btn-icon btn-soft-primary"><i class="mdi mdi-stack-overflow mdi-18px icons"></i></a>
          </li>
          <li class="list-inline-item mb-0">
            <a href="javascript:void(0)" class="btn btn-icon btn-soft-primary" data-toggle="modal"
               data-target="#productview"><i class="mdi mdi-account-outline mdi-18px icons"></i></a>
          </li>
        </ul>
        <!--end login button-->
        <!--end login button-->
        <!-- End Logo container-->
        <div class="menu-extras">
          <div class="menu-item">
            <!-- Mobile menu toggle-->
            <a class="navbar-toggle" @click="toggleMenu()" :class="{ open: isCondensed === true }">
              <div class="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </a>
            <!-- End mobile menu toggle-->
          </div>
        </div>

        <div id="navigation">
          <!-- Navigation Menu-->
          <ul class="navigation-menu" :class="{ 'nav-light': navLight === true }">
            <li>
              <router-link to="/" class="side-nav-link-ref">Home</router-link>
            </li>
            <li>
              <router-link to="/buy-plans"  class="side-nav-link-ref">Investment Plans</router-link>
            </li>
            <li>
              <router-link to="/faq" class="side-nav-link-ref">FAQs</router-link>
            </li>
            <li class="has-submenu">
              <a href="javascript:void(0)" @click="onMenuClick">My Account</a>
              <span class="menu-arrow"></span>
              <ul class="submenu megamenu">
                <li>
                  <ul>
                    <li>
                      <router-link to="/profile/overview" class="side-nav-link-ref">Overview</router-link>
                    </li>
                    <li>
                      <router-link to="/profile/transactions" class="side-nav-link-ref">My Bills</router-link>
                    </li>
                    <li>
                      <router-link to="/profile/deposits" class="side-nav-link-ref">Deposits
                      </router-link>
                    <li>
                      <router-link :to="'/profile/withdraws'" class="side-nav-link-ref">Withdraws
                      </router-link>
                    </li>
                    <li>
                      <router-link :to="'/profile/myOrders'" class="side-nav-link-ref">My Order</router-link>
                    </li>
					<li>
					  <router-link :to="'/buy-plans'" class="side-nav-link-ref">Buy Plans</router-link>
					</li>
					<li>
					  <router-link :to="'/profile/referral'" class="side-nav-link-ref">referrals</router-link>
					</li>
					<li>
					  <router-link :to="'/profile/setting'" class="side-nav-link-ref">settings</router-link>
					</li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <!--end navigation menu-->
          <div class="buy-menu-btn d-none">
            <a class="btn" :class="{
            'btn-light': navLight === true,
            'btn-primary': navLight !== true,
          }" @click="logout">Sign out</a>
          </div>
          <!--end login button-->
        </div>
        <!--end navigation-->
      </div>
      <!--end container-->
    </header>
    <!--end header-->
    <!-- Navbar End -->
  </div>
</template>
