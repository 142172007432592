<script>
import {
  ArrowUpIcon,
  HeartIcon,
  EyeIcon,
  ShoppingCartIcon,
  KeyIcon,
  PhoneIcon,
  MailIcon,
  SettingsIcon,
  MapPinIcon,
  UserIcon,
  UserCheckIcon
} from "vue-feather-icons";
import {Carousel, Slide} from "vue-carousel";
import storage from "../storage/index.js"
import Navbar2 from "@/components/navbar2";
import Navbar3 from "@/components/navbar3";
import Switcher from "@/components/switcher";
import Footer from "@/components/footer";
import overview from "@/views/overview"
import indexApi from "../apis/indexApi.js"
import navbar from "@/views/new-view/components/navbar";
import footerBar from "@/views/new-view/components/footerBar";

/**
 * Shop-profile component
 */
export default {
  data() {
    return {dashBoard: {}};
  },
  components: {
    Navbar2,
    Navbar3,
    navbar,
    Switcher,
    Footer,
    Carousel,
    Slide,
    ArrowUpIcon,
    HeartIcon,
    EyeIcon,
    ShoppingCartIcon,
    KeyIcon,
    PhoneIcon,
    MailIcon,
    SettingsIcon,
    MapPinIcon,
    UserIcon,
    UserCheckIcon,
    footerBar
  },
  watch: {
  },
  created() {
    // alert(this.$route.path)
    //if login
    let userId = storage.localGet("userId")
    if (userId) {
      // this.$router.push("/profile/overview")
      if(this.$route.path === '/profile'){
        this.$router.push('/profile/overview').catch(error => console.error(error))
      }
    } else {
      location.href = ("/auth-login")
    }
    if (userId) {
      this.login = true
      // indexApi.dashBoard(res => {
      //   if (res.code != 200) {
      //     storage.localDel("userId")
      //   } else {
      //     this.dashBoard = res.data
      //   }
      // })
    }
  },
  mounted() {
  },
  methods: {
    handleSelect(key, keyPath) {
      this.$router.push({
        path: key,
        params: {data: 'query'}
      })
    }
  }
};
</script>

<template>
  <div>
    <!--    <Navbar3 class="phone" />-->
<!--    <Navbar2/>-->
    <!--    <navbar></navbar>-->
    <!--end section-->
    <navbar></navbar>
    <section>
      <div class="container">
        <el-row>
          <el-col :span="5">
            <div class="window"
                 style="margin-top: 50px;margin-bottom: 50px;margin-right: 20px;border-radius: 28px;box-shadow: 0 0 5px #ddd;min-height: 300px;overflow: hidden;">
<!--              <div style="margin-left: 25px;margin-top: 25px;margin-bottom: 10px;">-->
<!--                <h5>Available Balance</h5>-->
<!--                <div style="color: gray;">${{ dashBoard.balance }}</div>-->
<!--              </div>-->
              <el-menu
                  style="border: none;"
                  :default-active="$route.path">
                <!--                  @select="handleSelect"-->
                <!--              >-->
                <router-link to="/profile/overview">
                  <el-menu-item index="/profile/overview">
                    <i class="el-icon-data-analysis"></i>
                    <span slot="title">
									Dashboard
								</span>
                  </el-menu-item>
                </router-link>
                <router-link to="/profile/transactions">
                  <el-menu-item index="/profile/transactions">
                    <i class="el-icon-money"></i>
                    <span slot="title">
						        	My Bills
						        </span>
                  </el-menu-item>
                </router-link>
                <router-link to="/profile/deposits">
                  <el-menu-item index="/profile/deposits">
                    <i class="el-icon-sell"></i>
                    <span slot="title">
						        	Deposits
						        </span>
                  </el-menu-item>
                </router-link>
                <router-link to="/profile/withdraws">
                  <el-menu-item index="/profile/withdraws">
                    <i class="el-icon-sold-out"></i>
                    <span slot="title">
							    	Withdraws
							    </span>
                  </el-menu-item>
                </router-link>
<!--                <router-link to="/profile/refund">-->
<!--                  <el-menu-item index="/profile/refund">-->
<!--                    <i class="el-icon-wallet"></i>-->
<!--                    <span slot="title">-->
<!--							    	Refund-->
<!--							    </span>-->
<!--                  </el-menu-item>-->
<!--                </router-link>-->
                <router-link to="/profile/myOrders">
                  <el-menu-item index="/profile/myOrders">
                    <i class="el-icon-shopping-cart-full"></i>
                    <span slot="title">
							    	My Orders
							    </span>
                  </el-menu-item>
                </router-link>
                <router-link to="/plans">
                  <el-menu-item index="/plans">
                    <i class="el-icon-position"></i>
                    <span slot="title">
										Buy Plans
									</span>
                  </el-menu-item>
                </router-link>
                <router-link to="/profile/referral">
                  <el-menu-item index="/profile/referral">
                    <i class="el-icon-user"></i>
                    <span slot="title">
							    	Referrals
							    </span>
                  </el-menu-item>
                </router-link>
                <router-link to="/profile/setting">
                  <el-menu-item index="/profile/setting">
                    <i class="el-icon-setting"></i>
                    <span slot="title">
							    	Settings
							    </span>
                  </el-menu-item>
                </router-link>
              </el-menu>
            </div>
          </el-col>
          <el-col :xl="18" :lg="18" :span="18" :xs="24">
            <router-view/>
          </el-col>
        </el-row>
      </div>
    </section>

    <!--end section-->
    <!-- End -->
    <!--end section-->
    <footerBar></footerBar>
<!--    <Footer/>-->
    <!-- Footer End -->
<!--    <Switcher/>-->
    <!-- Back to top -->
<!--    <a-->
<!--        href="javascript: void(0);"-->
<!--        class="btn btn-icon btn-primary back-to-top"-->
<!--        id="back-to-top"-->
<!--        v-scroll-to="'#topnav'"-->
<!--    >-->
<!--      <arrow-up-icon class="icons"></arrow-up-icon>-->
<!--    </a>-->
    <!-- Back to top -->
  </div>
</template>
